/**
 * Detect if elements if in viewport
 *
 * @param {domNode} el
 * @returns
 */
export function isInViewport(elem) {
  if (!elem) return false;
  let x = elem.getBoundingClientRect().left;
  let y = elem.getBoundingClientRect().top;
  let ww = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  let hw = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  let w = elem.clientWidth;
  let h = elem.clientHeight;
  return y < hw && y + h > 0 && x < ww && x + w > 0;
}

/**
 * Preload image
 *
 * @param {string} src
 * @returns img
 */
export function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

/**
 * Get base 64
 *
 * @param {object} file
 * @returns
 */
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
